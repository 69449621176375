header {
  color: #ffffff;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 10px;
  background-color: #373867;
}

.brand {
  display: flex;
  align-items: center;
  margin-left: 20px;
  font-size: 1rem;
  font-weight: 100;
}

.brand :nth-child(2) {
  padding-right: 5px;
  padding-left: 10px;
  font-weight: 600;
}

.navbar {
  display: flex;
  align-items: center;
}

.logout button {
  color: white;
}

.container {
  width: 560px;
  padding: 20px;
}

.button {
  display: flex;
  justify-content: flex-end;
}

.button div {
  display: flex;
  align-items: end;
  margin-left: 10px;
}

.text {
  display: flex;
  flex-direction: row;
}
